<script>
  import { gtag } from "ga-gtag";
  import { onMount } from "svelte";
  import { scale } from "svelte/transition";

  let show = false;

  onMount(() => {
    setTimeout(() => (show = true), 600);
  });
</script>

<div class="h-full w-full grid place-items-center">
  <div class="flex flex-col gap-2.5">
    {#if show}
      <a in:scale={{ start: 0.8 }} on:click={() => gtag("event", "clickEmailFromContact")} href="mailto:kilo.meter@foxmail.com"> 欢迎邮箱联系 kilo.meter@foxmail.com </a>
      <div class="flex flex-row gap-2.5">
        <a in:scale={{ start: 0.8, delay: 100 }} on:click={() => gtag("event", "clickFreeChatFromContact")} class="animate-pulse animate-duration-1000 !ring-white/70" href="https://github.com/CNSeniorious000/free-chat"> 欢迎 🌟 本项目 </a>
        <a in:scale={{ start: 0.8, delay: 250 }} on:click={() => gtag("event", "clickOriginalRepoFromContact")} href="https://github.com/anse-app/chatgpt-demo"> 原项目仓库 </a>
      </div>
      <div class="flex flex-row gap-2.5">
        <a in:scale={{ start: 0.8, delay: 400 }} on:click={() => gtag("event", "clickMyGitHubAccountFromContact")} href="https://github.com/CNSeniorious000"> 我的 GitHub </a>
        <a in:scale={{ start: 0.8, delay: 550 }} on:click={() => gtag("event", "clickUnsplashFromContact")} class="!bg-pink-500/2 !ring-pink-500/60 hover:!bg-pink-500/10 hover:!ring-pink-500/80" href="https://unsplash.com/@muspi_merol"> 我的 Unsplash </a>
      </div>
      <div class="flex flex-row gap-2.5">
        <a in:scale={{ start: 0.8, delay: 700 }} on:click={() => gtag("event", "clickWeixinFromContact")} class="!bg-[#42b98308] !ring-[#42b983c0] hover:!bg-[#42b98318] hover:!ring-[#42b983]" href="https://assets.frechat.xyz/MM%E5%AE%9E%E9%AA%8C%E7%94%B0%E4%BA%8C%E7%BB%B4%E7%A0%81-01.png"> 我的个人微信公众号 </a>
        <a in:scale={{ start: 0.8, delay: 850 }} on:click={() => gtag("event", "clickMyBlog")} href="https://muspimerol.site/"> 我的博客 </a>
      </div>
      <a in:scale={{ start: 0.8, delay: 1000 }} on:click={() => gtag("event", "clickSponsorshipFromContact")} href="https://assets.frechat.xyz/44f8217085367ed2add38a2d19eef30.jpg"> 欢迎大额捐赠支持鼓励我✌ </a>
    {/if}
  </div>
</div>

<style>
  a {
    --uno: p-3 hover:bg-true-gray-800 ring-true-gray-800 hover:ring-true-gray-500 ring-1.2 hover:ring-1.6 transition-all rounded-xl w-fit;
  }
</style>
