<script>
  // @ts-ignore
  import { gtag } from "ga-gtag";
  import { browser } from "$app/environment";

  let domains = [
    // { name: "国内可用线路 1", href: "//chat.6.bnu120.space/", description: "DogeCloud 提供 CDN 服务" },
    // { name: "国内可用线路 2", href: "//chat.5.bnu120.space/", description: "Tencent Cloud 提供 CDN 服务" },
    // { name: "备用线路", href: "//chat2.bnu120.space/", description: "Cloudflare 提供 CDN 服务" },
    // { name: "国外建议使用", href: "//a.free-chat.asia/", description: "Netlify Edge Function" },
    { name: "⭐ Endless Chat", href: "https://e10.frechat.xyz/", description: "已可免费使用 gpt-4o-mini" },
    { name: "⭐ Endless 备用1", href: "https://e9.free-chat.asia/", description: "已可免费使用 gpt-4o-mini" },
    { name: "⭐ Endless 备用2", href: "https://e9.frechat.xyz/", description: "已可免费使用 gpt-4o-mini" },
    { name: "⭐ Endless 备用3", href: "https://she2.frechat.xyz/", description: "已可免费使用 gpt-4o-mini" },
    { name: "⭐ Endless 备用4", href: "https://e8.frechat.xyz/", description: "已可免费使用 gpt-4o-mini" },
    // { name: "⭐ Endless 备用2", href: "https://she1.free-chat.asia/", description: "1 月 25 日最新发布的模型已上线" },
    // { name: "⭐ Endless 备用", href: "https://she1.free-chat.asia/", description: "已接入最新的 gpt-3.5-turbo-1106 模型" },
    // { name: "⭐ Endless Chat", href: "https://spark.frechat.xyz/", description: "已接入最新的讯飞星火认知大模型v3.0模型" },
    // { name: "⭐ Endless Chat", href: "https://c2.muspimerol.site/", description: "焕然一新，既快又不限上下文长度" },
    { name: "🌟 Endless Chat 国外访问", href: "https://endless-chat.vercel.app/", description: "第三方域名，低失联风险" },
    // { name: "✨ Free Anse", href: "https://a6.frechat.xyz/", description: "多会话、编辑上下文、分享图片、参数调节" },
    // { name: "💸 免费 OpenAI 账号", href: "https://accounts.free-chat.asia/", description: "可用于登录 ChatGPT 官网 / APP" },
    { name: "👻 在线 python", href: "https://py3.online/", description: "自带 AI 纠错" },
    // { name: "🚀 umami dashboard", href: "https://umami.muspimerol.site/share/H9ap6t613CJXsmpn/Free%20Chat", description: "来看看咱们 Free Chat 的统计信息 !" },
    { name: "🎨 Promplate", href: "https://zh.promplate.dev/py", description: "我用的提示工程框架" },
    // { name: "👀 解析LLM生成JSON的JS库", href: "https://www.npmjs.com/package/partial-json", description: "有用的话可以给我点颗星哈哈" },
    // { name: "👀 解析LLM返回的JSON的DEMO", href: "https://zh.promplate.dev/partial-json-parser", description: "效果实时演示" },
    // { name: "👀 16K 线路 Legacy", href: "https://3v.free-chat.asia/", description: "无滑动窗口机制，挺浪费的，不推荐" },
    // { name: "🥀 Free Chat Legacy", href: "https://h.free-chat.asia/", description: "不推荐，即将下线" },
  ];

  let mounted = false;

 // @ts-ignore
   $: if (browser) setTimeout(() => (mounted = true), 1);
</script>

<div class="bottom-7 left-7 absolute">
  <!-- <a class="relative" href="https://shopmus.fxopenai.xyz/">
    <img class="rounded-lg mb-4 ml-1 w-55" src="https://assets.frechat.xyz/934da3867a544cc9a15f2c359df7e9b.jpg" alt="advertisement" />
    <div class="text-white transition-all bottom-6 left-3 text-1 absolute uppercase group-hover:op-20">广告 advertisement</div>
  </a> -->
  <!-- <div class="font-serif tracking-wide ml-1.2 mb-1.5 text-sm text-true-gray-400">由于国内封杀，本站域名经常更换</div> -->
  <!-- <div class="font-serif tracking-wide ml-1.2 mb-3 text-sm text-true-gray-200 font-bold">因此建议收藏且仅收藏本页面</div> -->
  <div class="flex flex-col gap-3 mb-6.5 text-3.3 tracking-wide ml-1.2 text-true-gray-200 ws-nowrap">
    <div>OpenAI的API已经没有羊毛薅了</div>
    <div class="decoration-line-through decoration-red decoration-0.25em">现在GPT的线路都是基于逆向ChatGPT的了</div>
    <div>逆向也没有了，所以暂时只免费提供其它模型</div>
    <div class="text-cyan-2">当然，填写自己的 API Key 仍然可以用 GPT-4</div>
  </div>
  <h1 class="m-0 transition-all ease-out text-true-gray-200 text-6xl duration-500">Free Chat</h1>
  <!-- <div class="font-mono mt-3 text-true-gray-400">Forked from <a href="https://github.com/anse-app/chatgpt-demo" class="text-true-gray-200">@anse/chatgpt-demo</a></div> -->
  <a href="https://stats.uptimerobot.com/k0yE1CkKGL" on:click={() => gtag("event", "clickStatusFromDomains")} class="rounded flex flex-row bg-true-gray-800 mt-4 w-fit p-3 transition-all text-true-gray-100 gap-1 block items-center hover:bg-true-gray-200 hover:text-true-gray-950">
    点此查看 <span class="font-bold">Status Page</span> <span class="text-xl i-ic-round-play-arrow inline-block" />
  </a>
</div>
<div class="flex flex-col h-full pb-5 right-0 left-38% ring-1 ring-true-gray-200/10 absolute justify-end">
  <div class="h-10 w-full pl-5 text-true-gray-100 animate-fade-in animate-duration-300 tracking-wider">当前可用线路以及其它个人项目：</div>
  {#each domains as { name, href, description }, i}
    <div style:--delay="{i * 100}ms" style:--duration="{700 + i * 200}ms" class="animate-(fade-in duration-$duration ease-out delay-$delay both)">
      <!-- <div class:-translate-x-0.8={!mounted} class:op-0={!mounted} style:--delay="{i * 100}ms" style:--duration="{700 + i * 100}ms" class="ease-out delay-$delay duration-$duration"> -->
      <div style:--delay="{i * 100}ms" style:--duration="{700 + i * 100}ms" class="animate-(duration-$duration ease-out delay-$delay flip-in-x both)">
        <a {href} class="flex flex-row h-10.8 transition gap-2 block items-center hover:bg-true-gray-50/5" on:click={() => gtag("event", "clickLinkFromDomains")}>
          <div class="h-full grid pl-5 place-items-center">
            <div class="font-quicksand font-700 text-true-gray-600 w-3 [a:hover_&]:text-true-gray-100 transition-color">{i + 1}</div>
          </div>
          <div class="font-bold text-true-gray-100">{name}</div>
          <div class="flex-grow">{description}</div>
          <div class="rounded-l-sm h-full grid transition icon-container place-items-center">
            <div class="px-3 i-ic-round-play-arrow block" />
          </div>
        </a>
      </div>
    </div>
  {/each}
</div>

<svelte:head>
  {#each domains as { href }}
    <link rel="prefetch" {href} />
  {/each}
  <link rel="preload" href="Mona-Sans.woff2" crossorigin="anonymous" as="font" type="font/woff2" />
  <style>
    @font-face {
      font-family: "Mona Sans";
      src:
        url("Mona-Sans.woff2") format("woff2 supports variations"),
        url("Mona-Sans.woff2") format("woff2-variations");
      font-weight: 200 900;
      font-stretch: 75% 125%;
    }
  </style>
</svelte:head>

<style>
  a:hover > .icon-container {
    --uno: bg-true-gray-200 text-true-gray-800;
  }

  h1 {
    font-family: "Mona Sans";
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 2);
    font-weight: 200;
  }

  :global(.group:hover) h1 {
    font-stretch: 80%;
    font-weight: 800;
  }
</style>
